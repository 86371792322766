import gql from "graphql-tag";
import { JobsPageFragment, AllJobsFragments } from "../fragments/job-fragments";

export const GET_JOB_PAGE = gql`
  query {
    getJobPage {
      ...JobsPageFragment
    }
  }
  ${JobsPageFragment}
`;


export const GET_ALL_JOBS = gql`
  query {
    getJobs {
      ...AllJobsFragments
    }
  }

  ${AllJobsFragments}
`;

export const GET_JOB_BY_ID = gql`
 query($jobId: String!){
    getJob(jobId:$jobId){
      ...AllJobsFragments
    }
}
${AllJobsFragments}
`;

